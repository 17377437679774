



































































































import { Component, Prop, Vue } from "vue-property-decorator";
import {
  TitleTemplatePublic,
  RedCapital,
  SelectedCountry
} from "@/configuration";
import API from "@/api";
import DniField from "@/components/DniFieldBusiness.vue";
import ShowLoading from "@/components/panel/ShowLoading.vue";
import { isRucValid } from '@/utils';
import { EventBus } from '@/main'

@Component({
  metaInfo: {
    title: "Solicitar crédito",
    titleTemplate: TitleTemplatePublic,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang
    }
  },
  data() {
    return {
      RedCapital,
      SelectedCountry,
      valid: true,
      dialog: false,
      form:{
        name: '',
        ruc: '',
        clients: '',
        contact_name: '',
        phone:null,
        mail: '',
        type_contact: '',
      },
      clients: [],
      contact: [],
      rules: {
        notEmpty: [(v: any) => !!v || "Ingrese un valor"],
        name: [
          (v: any) => !!v || "Ingrese nombre",
          (v: any) =>
            v.length <= 255 || "Nombre no puede exceder los 255 caracteres."
        ],
        email: [
          (v: any) => !!v || "Ingrese correo electrónico",
          (v: any) => /[a-z0-9_]+(?:\.[a-z0-9_]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(v) || "La dirección debe ser válida"
        ],
        ruc: [
          // @ts-ignore
          (v: any) => !!v || `${RedCapital[SelectedCountry].DniName} es requerido`,
          // @ts-ignore
          (v: any) => isRucValid(v) || `El ${RedCapital[SelectedCountry].DniName} es inválido`
 
          
        ],
        phone: [
          (v: any) => !/[^\d]/gim.test(v) || "Tiene que ser sólo numeros",
          (v: any) => !!v || "Ingrese teléfono",
          (v:any) => v.length === 9 || "Telefono inválido"

        ],
        noSelect: [
            // @ts-ignore
            v => {
                if(!v || v == 0 || v == null || v == undefined){
                    return 'Campo requerido'
                }else{
                    return true
                }
            }
        ],
      },
      canShow: false,
      isLoading:false,
    };
  },
  methods: {
    async validate() {

      this.$data.isLoading = true;
      // @ts-ignore
      if (this.$refs.form.validate()) {
        API.credit({
          name: this.$data.form.name,
          ruc: this.$data.form.ruc,
          clients: this.$data.form.clients,
          nombre_contacto: this.$data.form.contact_name,
          phone: this.$data.form.phone,
          email: this.$data.form.mail,
          tipo_contacto_id: this.$data.form.type_contact
        })
        .then(res => {
          this.$data.dialog = true;
          this.$data.isLoading = false;
          setTimeout(() => {
            this.$router.push({
              path: "/"
            });
          }, 2000);
        })
        .catch(error => {
          EventBus.$emit('snack-error', error.data.message)
          this.$data.isLoading = false;

        });
      }
    },
    async cargarOpciones(){
      await API.obtenerClasificacionesCredito().then((data) => {
        const response = data.data;
        this.$data.clients = response.objetivo;
        this.$data.contact = response.tipo_contacto;
      });
    },
  },
  async beforeMount(){
    //@ts-ignore
    await this.cargarOpciones();

    this.$data.canShow = true;
  },
  components: {
    DniField,
    ShowLoading
  }
})
export default class Contact extends Vue {}
